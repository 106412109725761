import React, { useEffect, useState } from 'react'; // Moved useState import here
import './App.css';
import TodoList from './components/TodoList';
import MyAccount from './components/MyAccount';
import MyLists from './components/MyLists';
import TermsAndConditions from './components/TermsAndConditions';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CookieInfoModal from './components/CookieInfoModal'; // Removed curly braces if default export

function App() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if ("Notification" in window && Notification.permission !== "granted") {
            Notification.requestPermission();
        }
        
    }, []);

    useEffect(() => {
        const hasVisited = localStorage.getItem('hasVisited');
        if (!hasVisited) {
            setIsModalOpen(true);
            localStorage.setItem('hasVisited', 'true');
        }
    }, []);

    const handleClose = () => {
        setIsModalOpen(false);
    };

    return (
        <Router>
            <div className="App">
                <CookieInfoModal isOpen={isModalOpen} onClose={handleClose} />
                <Routes>
                    <Route path="/" element={<TodoList />} />
                    <Route path="/myaccount" element={<MyAccount />} />
                    <Route path="/mylists" element={<MyLists />} />
                    <Route path="/terms" element={<TermsAndConditions />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
