// TermsAndConditions.js

import React from 'react';
import BackButton from './BackButton'; // Adjust the import path as necessary
import './TermsAndConditions.css'; // Import CSS file for styling

function TermsAndConditions() {
  return (
    <div className="container">
      <div className='terms-header'>
        <BackButton />
        <h1>Terms and Conditions</h1>
      </div>
      <div className="terms-text">
        <p>
          <strong>Terms and Conditions</strong>
          <ol>
            <li><strong>Description:</strong> Our Task Possible App is a simple and intuitive tool designed to help users organize tasks efficiently. Users can easily add, delete, and mark tasks as completed, with the added convenience of local storage for seamless access to their lists.</li>
            <li><strong>Usage:</strong> By using our Task Possible App, you agree to abide by these terms and conditions. This includes complying with any applicable laws and regulations while using the app.</li>
            <li><strong>Responsibility:</strong> Users are solely responsible for the content they add to the to-do list. We do not assume any liability for the accuracy, completeness, or legality of the tasks entered by the users.</li>
            <li><strong>Data Privacy:</strong> We are committed to protecting the privacy of our users. Any personal information provided while using the app, including data stored locally, will be handled in accordance with our Privacy Policy.</li>
            <li><strong>Local Storage:</strong> The Task Possible App utilizes local storage to save user data locally within the user's web browser. This enables users to access their to-do lists even after closing or refreshing the app, providing a seamless user experience. <strong>Warning Before Clearing Cookies</strong>  Before you remove cookies, remember it will delete all your saved lists and todo item lists. Please ensure to save your data to avoid loss.

Click the Save button to save your current todo list.
Open the menu in the top right corner and click on the My List tab.
Click the Print icon next to your saved todo list. When the print window opens, choose whether to send it to a printer or save it to your PC as a PDF.</li>
            <li><strong>Intellectual Property:</strong> The Task Possible App and all its associated content, including but not limited to code, design, and logos, are the intellectual property of the app owner. Users are not permitted to reproduce, distribute, or modify any part of the app without prior consent.</li>
            <li><strong>Use Restrictions:</strong> Users are prohibited from using the app for any unlawful or unauthorized purpose. This includes but is not limited to engaging in any activity that disrupts the functioning of the app or interferes with other users' access.</li>
            <li><strong>Termination:</strong> We reserve the right to terminate access to the Task Possible App at any time without prior notice if users violate these terms and conditions or engage in any behavior deemed harmful to the app or its users.</li>
            <li><strong>Changes to Terms:</strong> We may update or modify these terms and conditions from time to time without prior notice. Users are encouraged to review the terms periodically to stay informed of any changes.</li>
            <li><strong>Contact Information:</strong> If you have any questions or concerns regarding these terms and conditions, please contact us at napiorka@hotmail.com</li>
          </ol>
          By using our Task Possible App, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you should refrain from using the app.
        </p>
      </div>
    </div>
  );
}

export default TermsAndConditions;
