import React, { useState , useEffect} from 'react';
import './ReminderForm.css'; // Import CSS file for styling

const ReminderForm = ({ todo, onClose }) => {
  const [reminderDate, setReminderDate] = useState('');
  const [reminderTime, setReminderTime] = useState('');

  useEffect(() => {
    if (todo.isComplete) {
      onClose();
    }
  }, [todo.isComplete, onClose]);

  const handleReminderSubmit = () => {
    if (todo.isComplete) {
      alert('Cannot set a reminder for a completed todo.');
      return;
    }
  
    if (!reminderDate || !reminderTime) {
      alert('Please set both date and time for the reminder.');
      return;
    }
  
    const reminderDateTime = new Date(`${reminderDate}T${reminderTime}:00`);
    const currentTime = new Date();
    currentTime.setSeconds(0, 0); // This ensures we ignore the current second and millisecond
  
    if (reminderDateTime <= currentTime) {
      alert('Failed to set reminder. Please choose a future time.');
      return;
    }
  
    const newReminder = {
      todoId: todo.id,
      text: todo.text,
      date: reminderDate,
      time: reminderTime
    };
  
    const existingReminders = JSON.parse(localStorage.getItem('reminders')) || [];
    existingReminders.push(newReminder);
    localStorage.setItem('reminders', JSON.stringify(existingReminders));
  
    // Calculate time until the reminder
    const timeUntilReminder = reminderDateTime.getTime() - currentTime.getTime();
    setTimeout(() => {
      const dateTimeString = `${reminderDate}T${reminderTime}:00`; // Ensure this format is consistent
      handleNotificationPermission(` ${todo.text}`, todo.id, dateTimeString);
    }, timeUntilReminder);
    
  
    alert('Reminder set successfully!');
    onClose();
  };
  
  const handleNotificationPermission = async (text, todoId, dateTime) => {
    let permission = Notification.permission;
    if (permission !== "granted" && permission !== "denied") {
      permission = await Notification.requestPermission();
    }
    if (permission === "granted") {
      showNotification(text, todoId, dateTime);
    }
  };
  
  const showNotification = (text, todoId, dateTime) => {
    const notificationOptions = {
        body: text,  // The main text content of the notification
        icon: '/icon.png',  // Path to the icon image
        badge: '/badge.png'  // Path to the badge image
    };
    new Notification("Mission: Task Possible!", notificationOptions);
    removeReminderFromLocalStorage(todoId, dateTime);
};

  
  const removeReminderFromLocalStorage = (todoId, exactDateTime) => {
    const reminders = JSON.parse(localStorage.getItem('reminders')) || [];
    // Convert exactDateTime back to Date object for accurate comparison
    const targetDateTime = new Date(exactDateTime).getTime();
  
    const updatedReminders = reminders.filter(reminder => {
      const reminderDateTime = new Date(`${reminder.date}T${reminder.time}:00`).getTime();
      return reminder.todoId !== todoId || reminderDateTime !== targetDateTime;
    });
  
    localStorage.setItem('reminders', JSON.stringify(updatedReminders));
  };
  
  
  
  
  const handleCancel = () => {
    // Close the reminder form without setting the reminder
    onClose();
  };

  return (
    <div className="reminder-form">
      <h2>Set Reminder</h2>
      <div className="form-group">
        <label>Date:</label>
        <input
          type="date"
          value={reminderDate}
          onChange={(e) => setReminderDate(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Time:</label>
        <input
          type="time"
          value={reminderTime}
          onChange={(e) => setReminderTime(e.target.value)}
        />
      </div>
      <div className="button-group">
        <button onClick={handleReminderSubmit}>Set Reminder</button>
        <button onClick={handleCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default ReminderForm;
