// MyAccount.js

import React from 'react';
import BackButton from './BackButton'; // Adjust the import path as necessary
import './MyAccount.css'; // Import the CSS for styling

function MyAccount() {
  return (
    <div className='container'>
      <div className='account-header'>
        <BackButton />
        <h1>My Account</h1>
      </div>
      <p>Soon</p>
      {/* Add more content and functionality as needed */}
    </div>
  );
}

export default MyAccount;
