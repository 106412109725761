// BackButton.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RiArrowGoBackFill } from "react-icons/ri";

const DEFAULT_RETURN_PATH = '/'; // Set your default return path here

function BackButton({ returnPath = DEFAULT_RETURN_PATH }) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(returnPath);
  };

  return (
    <button onClick={goBack} className="back-button">
      < RiArrowGoBackFill />
    </button>
  );
}

export default BackButton;
