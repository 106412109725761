import React, { useState } from 'react';
import { IoMenu } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import ToggleSwitch from './ToggleSwitch'; // Import the ToggleSwitch component
//import Theme from './Theme';

function Header({ isAnimationOn, toggleAnimation }) {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <div className="header">
       <ToggleSwitch isOn={isAnimationOn} handleToggle={toggleAnimation} />
      <div className="menu-icon" onClick={toggleMenu}>
        <IoMenu /> {/* Menu Icon */}
        {showMenu && (
          <div className="dropdown-menu">
            
            

            {/* Navigation Links */}
            {/* <Link to="/myaccount">My Account</Link> */}
            <Link to="/mylists">My Saved Lists</Link>
            <Link to="/terms">T&C</Link>
          </div>
        )}
      </div>

    </div>
  );
}

export default Header;
