import React, { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useUndo from 'use-undo';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { isMobile } from 'react-device-detect';

function TodoForm(props) {
  const [
    inputState,
    { set: setInput, undo: undoInput, redo: redoInput, canUndo, canRedo },
  ] = useUndo(props.edit ? props.edit.value : '');
  const { present: input } = inputState;

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [addButtonText, setAddButtonText] = React.useState('Hit Me with Tasks!');
  const inputRef = useRef(null);

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.onSubmit({
      id: props.edit ? props.edit.id : uuidv4(),
      text: input.trim(),
    });
    setInput(''); // Clear input after submission
  };

  // Handle keyboard shortcuts for undo and redo, but only if the input field is focused
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (document.activeElement !== inputRef.current) return; // Only proceed if inputRef is focused

      if ((e.ctrlKey || e.metaKey) && e.key === 'z') {
        e.preventDefault();
        if (canUndo) {
          undoInput();
        }
      } else if ((e.ctrlKey || e.metaKey) && (e.key === 'y' || (e.shiftKey && e.key === 'Z'))) {
        e.preventDefault();
        if (canRedo) {
          redoInput();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [canUndo, canRedo, undoInput, redoInput]);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowWidth < 768) {
      setAddButtonText('Add');
    } else {
      setAddButtonText('Hit Me with Tasks!');
    }
  }, [windowWidth]);

  return (
    <form onSubmit={handleSubmit} className='todo-form'>
      {props.edit ? (
        <>
          <input
            placeholder='Update your item'
            value={input}
            onChange={handleChange}
            name='text'
            ref={inputRef}
            className='todo-input edit'
            aria-label='Update your todo'
            data-tooltip-id="updateTooltip"
            data-tooltip-content="Update the task"
          />
          <button
            onClick={handleSubmit}
            className='todo-button edit'
            type='submit'
            data-tooltip-id="confirmTooltip"
            data-tooltip-content="Confirm update"
          >
            Confirm
          </button>
        </>
      ) : (
        <>
          <input
            placeholder='Add a todo'
            value={input}
            onChange={handleChange}
            name='text'
            className='todo-input'
            ref={inputRef}
            aria-label='Add a new todo'
            data-tooltip-id="addTodoTooltip"
            data-tooltip-content="Type your task here"
          />
          <button
            onClick={handleSubmit}
            className='todo-button'
            type='submit'
            data-tooltip-id="saveTooltip"
            data-tooltip-content="Press the button to add the task"
          >
            {addButtonText}
          </button>
        </>
      )}
      
      {!isMobile && (
        <>
          <ReactTooltip id="undoTooltip" place="top" effect="solid" />
          <ReactTooltip id="redoTooltip" place="top" effect="solid" />
          <ReactTooltip id="updateTooltip" place="top" type="dark" effect="solid" />
          <ReactTooltip id="confirmTooltip" place="top" type="dark" effect="solid" />
          <ReactTooltip id="addTodoTooltip" place="top" type="dark" effect="solid" />
          <ReactTooltip id="saveTooltip" place="top" type="dark" effect="solid" />
          <ReactTooltip id="moveTopTooltip" place="top" type="dark" effect="solid" />
          <ReactTooltip id="deleteTooltip" place="top" type="dark" effect="solid" />
          <ReactTooltip id="editTooltip" place="top" type="dark" effect="solid" />
          <ReactTooltip id="reminderTooltip" place="top" type="dark" effect="solid" />
          <ReactTooltip id="diceTooltip" place="top" type="dark" effect="solid" />
        </>
      )}
    </form>
  );
}

export default TodoForm;
