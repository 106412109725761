import React, { useState, useEffect } from 'react';
import BackButton from './BackButton';
import { RiDeleteBin6Line, RiPrinterLine, RiSave3Line, RiShareForwardLine } from 'react-icons/ri';
import './MyLists.css';
import CookieInfoModal from './CookieInfoModal';
import { AiOutlineWarning } from 'react-icons/ai';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { isMobile } from 'react-device-detect';

function MyLists() {
  const [savedLists, setSavedLists] = useState([]);
  const [expandedList, setExpandedList] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const lists = JSON.parse(localStorage.getItem('savedTodoLists') || '[]');
    lists.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    setSavedLists(lists);
  }, []);

  const toggleList = (id) => {
    setExpandedList(expandedList === id ? null : id);
  };

  const deleteList = (listIndex) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this saved list?");
    if (confirmDelete) {
      const updatedLists = savedLists.filter((_, index) => index !== listIndex);
      setSavedLists(updatedLists);
      localStorage.setItem('savedTodoLists', JSON.stringify(updatedLists));
    }
  };

  const printList = (listIndex) => {
    const listToPrint = savedLists[listIndex];
    let iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0px';
    iframe.style.height = '0px';
    iframe.style.border = 'none';
    document.body.appendChild(iframe);

    const printContent = `
      <html>
        <head>
          <title>Print List</title>
          <style> body { font-family: Arial, sans-serif; } ul { list-style-type: disc; margin-left: 20px; } li { word-wrap: break-word; } </style>
        </head>
        <body>
          <h1>List saved on: ${new Date(listToPrint.timestamp).toLocaleDateString()}</h1>
          <ul> ${listToPrint.todos.map(todo => `<li>${todo.text}</li>`).join('')} </ul>
        </body>
      </html>`;
      
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(printContent);
    iframe.contentWindow.document.close();
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
    setTimeout(() => document.body.removeChild(iframe), 1000);
  };

  // Function to save the list as a .txt file
  const saveListToFile = (listIndex) => {
    const list = savedLists[listIndex];
    const content = `List saved on: ${new Date(list.timestamp).toLocaleDateString()}\n\n${list.todos.map(todo => `- ${todo.text}`).join('\n')}`;
    const blob = new Blob([content], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `Todo_List_${new Date(list.timestamp).toLocaleDateString()}.txt`;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  // Function to share the list using the Web Share API
  const shareList = (listIndex) => {
    const list = savedLists[listIndex];
    const content = `List saved on: ${new Date(list.timestamp).toLocaleDateString()}\n\n${list.todos.map(todo => `- ${todo.text}`).join('\n')}`;
    if (navigator.share) {
      navigator.share({
        title: 'My Todo List',
        text: content,
      }).catch(error => console.error('Error sharing:', error));
    } else {
      alert('Sharing is not supported on this device.');
    }
  };

  return (
    <div className='container'>
      <div className='lists-header'>
        <span data-tooltip-id="backTooltip" data-tooltip-content="Return to previous page">
          <BackButton />
        </span>
        <h1>
          My Saved Lists 
          <span data-tooltip-id="modalTooltip" data-tooltip-content="Warning: Clearing cookies will erase saved lists. See details for saving options.">
            <button onClick={toggleModal} className="warning-icon-button" aria-label="Warning: Clearing cookies will erase saved lists. See details for saving options.">
              <AiOutlineWarning />
            </button>
          </span>
        </h1>
      </div>
      <CookieInfoModal isOpen={isModalOpen} onClose={toggleModal} />

      {savedLists.map((list, listIndex) => (
        <div key={listIndex} className="saved-list">
          <button onClick={() => toggleList(listIndex)} className="list-toggle">
            List saved on: {new Date(list.timestamp).toLocaleDateString()}
          </button>
          <span data-tooltip-id="deleteTooltip" data-tooltip-content="Delete this saved list permanently">
            <RiDeleteBin6Line onClick={() => deleteList(listIndex)} className="delete-list-icon" />
          </span>
          <span data-tooltip-id="printTooltip" data-tooltip-content="Print this saved list">
            <RiPrinterLine onClick={() => printList(listIndex)} className="print-list-icon" />
          </span>
          <span data-tooltip-id="saveTooltip" data-tooltip-content="Save this list to your device">
            <RiSave3Line onClick={() => saveListToFile(listIndex)} className="save-list-icon" />
          </span>
          <span data-tooltip-id="shareTooltip" data-tooltip-content="Share this list">
            <RiShareForwardLine onClick={() => shareList(listIndex)} className="share-list-icon" />
          </span>
          {expandedList === listIndex && (
            <div className="todo-list">
              <ul>
                {list.todos.map((todo, todoIndex) => (
                  <li key={todoIndex} className="todo-item">
                    {todo.text}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}

      {!isMobile && (
        <>
          <ReactTooltip id="backTooltip" place="top" effect="solid" />
          <ReactTooltip id="modalTooltip" place="top" effect="solid" />
          <ReactTooltip id="deleteTooltip" place="top" effect="solid" />
          <ReactTooltip id="printTooltip" place="top" effect="solid" />
          <ReactTooltip id="saveTooltip" place="top" effect="solid" />
          <ReactTooltip id="shareTooltip" place="top" effect="solid" />
        </>
      )}
    </div>
  );
}

export default MyLists;
