import React from 'react';


const CookieInfoModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-background">
      <div className="modal-content">
      <h2>Warning Before Clearing Cookies</h2>
      <p>Before you remove cookies, remember it will delete all your saved lists and todo item lists. <strong>Please ensure to save your data to avoid loss.</strong></p>
      <ol>
        <li>Click the <strong>Save</strong> button to save your current todo list.</li>
        <li>Open the menu in the top right corner and click on the <strong>My Saved List</strong> tab.</li>
        <li>Select your list and click the <strong>Print icon</strong> next to your saved todo list. When the print window opens, choose whether to send it to a printer or save it to your PC as a PDF.</li>
      </ol>
      <button className="modal-button-close" onClick={onClose}>Close</button>
    </div>
    </div>
  );
}

export default CookieInfoModal;
