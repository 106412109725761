import React, { useState } from 'react';
import TodoForm from './TodoForm';
import { CiEdit } from 'react-icons/ci';
import { IoAlarmOutline } from "react-icons/io5";
import { RiDeleteBin6Line } from 'react-icons/ri';
import ReminderForm from './ReminderForm';
import { GrLinkTop } from "react-icons/gr";


const Todo = ({ todo, completeTodo, removeTodo, updateTodo, moveToTop }) => {
  const [edit, setEdit] = useState({
    id: null,
    value: ''
  });
  const [showReminderForm, setShowReminderForm] = useState(false); 

  const submitUpdate = value => {
    updateTodo(todo.id, value);
    setEdit({
      id: null,
      value: ''
    });
  };

  const handleReminderClick = () => {
      if (!todo.isComplete) {
        setShowReminderForm(true);
      }
  };

  const handleReminderClose = () => {
    setShowReminderForm(false);
  };

  if (edit.id) {
    return <TodoForm edit={edit} onSubmit={submitUpdate} />;
  }

  return (
    <div className={todo.isComplete ? 'todo-row complete' : 'todo-row'}>
      <div className="todo-text" onClick={() => completeTodo(todo.id)}>
        {todo.text}
      </div>
      
      <div className='icons'>
        <GrLinkTop 
          onClick={() => moveToTop(todo.id)} 
          className='move-icon' 
          data-tooltip-id="moveTopTooltip" 
          data-tooltip-content="Bring to the top" 
        />
        
        <RiDeleteBin6Line 
          onClick={() => removeTodo(todo.id)} 
          className='delete-icon' 
          data-tooltip-id="deleteTooltip" 
          data-tooltip-content="Delete the task" 
        />
        
        <CiEdit 
          onClick={() => setEdit({ id: todo.id, value: todo.text })} 
          className='edit-icon' 
          data-tooltip-id="editTooltip" 
          data-tooltip-content="Update the task" 
        />
        
        <IoAlarmOutline 
          onClick={handleReminderClick} 
          className='reminder-icon' 
          data-tooltip-id="reminderTooltip"
          data-tooltip-content="Set a reminder" 
        />
      </div>

      

      {showReminderForm && (
        <ReminderForm
          todo={todo}
          onClose={handleReminderClose}
        />
      )}

    </div>
  );
}

export default Todo;
